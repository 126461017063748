//ヘッダー
$(function() {
    var headerH = $("#header").outerHeight(true);
    function fixedAnime() {
        var scroll = $(window).scrollTop();
        if (scroll >= headerH){
            $('#header').addClass('header__scroll');
        }else{
            $('#header').removeClass('header__scroll');
        }    
    }

    $(window).scroll(function () {
        fixedAnime();
    });

    $(window).on('load', function () {
        fixedAnime();
    });
});

// gnav

$(function(){
	var _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';
	$(".menu").on(_touch,function() {
		$(".menu").toggleClass("active");
		//$('.gnav__wrapper').toggleClass("on");
		$(".nav").fadeToggle(200);
		return false;
	});
});


//sticky header1
// $(function() {
// 	var $win = $(window),
// 		$main = $('#main'),
// 		$nav = $('.gnav__item'),
// 		navHeight = $nav.outerHeight(),
// 		navPos = $nav.offset().top,
// 		onClass = 'on';
  
// 	$win.on('load scroll', function() {
// 	  var value = $(this).scrollTop();
// 	  if ( value > navPos ) {
// 		$nav.addClass(onClass);
// 		$('#mainnav').css('margin-top', '50px');
// 		$('#spnav').css('top', '50px');
// 	  } else {
// 		$nav.removeClass(onClass);
// 		//$main.css('margin-top', '0');
// 		$('#mainnav').css('margin-top', '0');
// 		$('#spnav').css('top', '111px');
// 	  }
// 	});
// });

//sticky header2
// $(function() {
// 	var $win = $(window),
// 		$main = $('#main'),
// 		$cta = $('#cta'),
// 		ctaHeight = $cta.outerHeight(),
// 		ctaPos = $cta.offset().top + 130,
// 		fixedClass = 'sticky';
  
// 	$win.on('load scroll', function() {
// 	  var value = $(this).scrollTop();
// 	  if ( value > ctaPos ) {
// 		$cta.addClass(fixedClass);
// 		//$('#cta').addClass('sticky');
// 	  } else {
// 		$cta.removeClass(fixedClass);
// 		//$main.css('margin-top', '0');
// 		//$('#cta').removeClass('sticky');
// 	  }
// 	});
// });

//scroll
$(function(){
	$('a[href^="#"]').click(function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});



//slider

$(document).ready(function(){
	$('#slide').slick({
		infinite: true,
		autoplay: true,
		// slidesToShow: 1,
		// slidesToScroll: 1,
		//centerMode: true,
		arrows: true,
		dots: false,
		fade: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 1000,
	});
	$('#gallery').slick({
		infinite: true,
		autoplay: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		//centerMode: true,
		//slidesToShow: 1,
		//slidesToScroll: 1,
		centerMode: true,
		variableWidth: true,
		arrows: true,
		dots: true,
		fade: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 1000,
	});
	$('#gallery__slide').slick({
		autoplay: false,
		dots: false,
		infinite: false,
		speed: 300,	
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true
	});
});



//animation

//   $(function(){
// 	//$('.anime').css("opacity","0");
// 	$(window).scroll(function (){
// 	  $(".anime").each(function(){
// 		var imgPos = $(this).offset().top;    
// 		var scroll = $(window).scrollTop();
// 		var windowHeight = $(window).height();
// 		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
// 		  $(this).addClass('move');
// 		} 
// 		// else {
// 		//   $(this).removeClass('move');
// 		//   // $(this).css({ 
// 		//   //   "font-size": "20px",
// 		//   //   "padding": "20px"
// 		//   // });
// 		// }
// 	  });
// 	  //Right
// 	  $(".anime-r").each(function(){
// 		var imgPos = $(this).offset().top;    
// 		var scroll = $(window).scrollTop();
// 		var windowHeight = $(window).height();
// 		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
// 		  $(this).addClass('move-r');
// 		}
// 	  });
// 	  //left
// 	  $(".anime-l").each(function(){
// 		var imgPos = $(this).offset().top;    
// 		var scroll = $(window).scrollTop();
// 		var windowHeight = $(window).height();
// 		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
// 		  $(this).addClass('move-l');
// 		}
// 	  });
  
// 	  //dilay
// 	  $(".anime-d").each(function(){
// 		var imgPos = $(this).offset().top;    
// 		var scroll = $(window).scrollTop();
// 		var windowHeight = $(window).height();
// 		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
// 		  $(this).addClass('move-d');
// 		}
// 	  });
// 	  //bottom
// 	  $(".anime-b").each(function(){
// 		var imgPos = $(this).offset().top;    
// 		var scroll = $(window).scrollTop();
// 		var windowHeight = $(window).height();
// 		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
// 		  $(this).addClass('move-b');
// 		}
// 	  });
  
// 	});
//   });